import { render, staticRenderFns } from "./SecondaryArticles.vue?vue&type=template&id=3b5f39d4&scoped=true&"
import script from "./SecondaryArticles.vue?vue&type=script&defer=true&lang=js&"
export * from "./SecondaryArticles.vue?vue&type=script&defer=true&lang=js&"
import style0 from "./SecondaryArticles.vue?vue&type=style&index=0&id=3b5f39d4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5f39d4",
  null
  
)

export default component.exports