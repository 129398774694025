<template>
	<div class="flex flex-col justify-between w-full space-y-2 lg:w-1/3">
		<router-link
			v-for="(article, index) in articles"
			:key="index"
			:to="article.url"
			class="relative mx-2 overflow-hidden lg:mb-0 aspect-[7/5]"
		>
			<WwImage
				:src="article.image.fileName"
				classes="rounded-xl"
				object-cover
				:alt="article.title"
				:width="798"
				:height="570"
				:image-path="BLOG_IMAGES"
				:lazy="false"
				fetch-priority="high"
			/>
			<h3
				class="absolute bottom-0 z-20 m-4 text-xl leading-6 text-left text-white z-1"
				v-html="article.title"
			/>
			<div class="black-gradient-to-b" />
		</router-link>
	</div>
</template>

<script defer>
import { BLOG_IMAGES } from '@/constants/images/image-folder-paths.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		articles: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			BLOG_IMAGES
		}
	}
}
</script>

<style scoped>
.black-gradient-to-b {
	@apply rounded-b-xl z-10 opacity-75 absolute left-0 bottom-0 w-full h-1/2;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
